import { useRailsContext } from 'components/rails-context'

export type IsActiveConfig = {
  /**
   * Whether the pathname should be compared exactly. If set to false this will compare the path based on the start of the string.
   */
  exactMatch?: boolean
  includeSearch?: boolean
}

export const useIsActive = (
  pathname: string | undefined | Array<string | undefined>,
  activePath?: string | null,
  config: IsActiveConfig = { exactMatch: true, includeSearch: false }
): boolean => {
  const serverSide = typeof location === 'undefined'
  const { request } = useRailsContext()
  if (activePath === pathname) return true

  // favour window's location.pathname if present in case any client side routing took place
  const currentPath = () => {
    if (serverSide) {
      return config?.includeSearch ? request.fullpath : request.path
    } else {
      return config?.includeSearch
        ? location.pathname + location.search
        : location.pathname
    }
  }
  if (!currentPath()) return false

  const pathnames = Array.isArray(pathname) ? pathname : [pathname]

  return pathnames.some((path) => {
    if (!path) return false
    const pathWithoutSearch = path.split('?')[0]
    const pathToMatch = config?.includeSearch ? path : pathWithoutSearch

    return config?.exactMatch
      ? pathToMatch === currentPath()
      : currentPath().startsWith(pathToMatch)
  })
}

// This method returns a function for checking the active path, so instead of needing to do
// const activeOrgPath = useIsActive('/orgs/x')
// const activeInvitePath = useIsActive('/orgs/x/invites')
// at the top level of the file (due to react hook constraints), you can do
// const isActive = useIsActivePath()
// const activeOrgPath = isActive('/orgs/x')
// const activeInvitePath = isActive('/orgs/x/invites')
// anywhere in the file
// TODO: remove the previous method and clean up the existing side nav
export const useIsActivePath = () => {
  const serverSide = typeof location === 'undefined'
  const { request } = useRailsContext()

  const isActive = (
    pathname: string | undefined | Array<string | undefined>,
    activePath?: string | null,
    config: IsActiveConfig = { exactMatch: true }
  ) => {
    if (activePath === pathname) return true

    // favour window's location.pathname if present in case any client side routing took place
    const currentPath = serverSide ? request.path : location.pathname
    if (!currentPath) return false

    const pathnames = Array.isArray(pathname) ? pathname : [pathname]

    return pathnames.some((path) => {
      if (!path) return false
      const pathWithoutSearch = path.split('?')[0]
      return config?.exactMatch
        ? pathWithoutSearch === currentPath
        : currentPath.startsWith(pathWithoutSearch)
    })
  }

  return {
    isActive,
  }
}
